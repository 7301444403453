import React from 'react'
import CardItem from 'components/CardItem'
import { productInfo } from './Product/Product.config'

function ProductCards() {
  var elements = []
  var cards = []

  const populateProducts = (p, i) => {
    elements = []
    cards.push(
      <CardItem
        key={i}
        src={p.img}
        text={p.description}
        label={p.name}
        path={'/products/' + i}
      />,
    )
    if (((i + 1) % 3 === 0 || i === productInfo.length - 1) && i !== 0) {
      if (i === productInfo.length - 1 && (i + 1) % 3 !== 0) {
        cards.push(<li key={'li' + i} className="cards__item"></li>)
      }
      elements.push(
        <ul className="cards__items" key={i}>
          {cards}
        </ul>,
      )
      cards = []
      return elements
    }
  }
  return (
    <div className="cards">
      <h1>ALPHA PEST PRODUCTS</h1>
      <div className="cards__container">
        <div className="cards__wrapper">
          {productInfo.map((p, i) => {
            return populateProducts(p, i)
          })}
        </div>
      </div>
    </div>
  )
}

export default ProductCards
