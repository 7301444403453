import RatBg from 'components/pages/Pests/Pest/images/img-pest-rat.jpg'
import AntBg from 'components/pages/Pests/Pest/images/img-pest-ant.jpg'
import FlyBg from 'components/pages/Pests/Pest/images/img-pest-fly.jpg'
import MosquitoBg from 'components/pages/Pests/Pest/images/img-pest-mosquito.jpg'
import CockroachBg from 'components/pages/Pests/Pest/images/img-pest-cockroach.jpg'
import TermiteBg from 'components/pages/Pests/Pest/images/img-pest-termite.jpg'

export const pestInfo = [
  {
    name: 'FLIES',
    bg: FlyBg,
    path: 'pest/fly',
    mgl: '-40px',
    profile: [
      'The most common flies found in Malaysia is the ',
      <b key="fly-profile">
        <i>Common Housefly (Musca domestica)</i>
      </b>,
      ' which belongs to the family Muscidae.',
    ],
    habitat: [
      'Flies live almost everywhere in the world except ',
      <b key="fly-habitat1">
        <i>Antarctica</i>
      </b>,
      ', but often associated with ',
      <b key="fly-habitat2">
        <i>moist environments and decaying matters</i>
      </b>,
    ],
    food: [
      'The adults feed on a variety of ',
      <b key="fly-food">
        <i>liquid or semi-liquid substances</i>
      </b>,
      ', as well as solid materials which have been softened by their saliva. ',
    ],
    time: [
      'Houseflies are typically active during the ',
      <b key="fly-time1">
        <i>daytime</i>
      </b>,
      ', especially during the warmer parts of the day. However, the activity level of houseflies can also depend on factors such as ',
      <b key="fly-time2">
        <i>temperature, light, and availability of food</i>
      </b>,
      '. They tend to be more active in warm weather and in areas where there is plenty of ',
      <b key="fly-time3">
        <i>food and moisture</i>
      </b>,
      '.',
    ],
    harm: [
      'It is known to spread diseases and bacteria such as ',
      <b key="fly-harm">
        <i>Salmonella, E.coli, Cholera, Typhoid fever</i>
      </b>,
      ' and etc.',
    ],
    weak: [
      'Housefiles are attracted to ',
      <b key="fly-weak">
        <i>light and heat,</i>
      </b>,
      ' so they may be more active around windoes or other sources of light.',
    ],
    knowledge: [
      'Flies has large compund eyes with up to ',
      <b key="fly-knowledge1">
        <i>4000 lenses</i>
      </b>,
      ' that give them a wide field of view allowing them to ',
      <b key="fly-knowledge2">
        <i>detect predators</i>
      </b>,
      '.',
    ],
  },
  {
    name: 'COCKROACHES',
    bg: CockroachBg,
    path: 'pest/cockroach',
    mgl: '-40px',
    profile: '',
    habitat: [''],
    food: '',
    time: '',
    harm: '',
    weak: '',
    knowledge: '',
  },
  {
    name: 'ANTS',
    bg: AntBg,
    path: 'pest/:ant',
    mgl: '-70px',
    profile: '',
    habitat: [''],
    food: '',
    time: '',
    harm: '',
    weak: '',
    knowledge: '',
  },
  {
    name: 'MOSQUITOES',
    bg: MosquitoBg,
    path: 'pest/mosquito',
    mgl: '-20px',
    profile: '',
    habitat: [''],
    food: '',
    time: '',
    harm: '',
    weak: '',
    knowledge: '',
  },
  {
    name: 'RATS',
    bg: RatBg,
    path: 'pest/rat',
    mgl: '-40px',
    profile: '',
    habitat: [''],
    food: '',
    time: '',
    harm: '',
    weak: '',
    knowledge: '',
  },
  {
    name: 'TERMITES',
    path: 'pest/termite',
    mgl: '-120px',
    bg: TermiteBg,
    profile: '',
    habitat: [''],
    food: '',
    time: '',
    harm: '',
    weak: '',
    knowledge: '',
  },
]
