import React from 'react'
import './IntegratedPest.css'
import ManageSearchIcon from '@mui/icons-material/ManageSearch'
import HealingIcon from '@mui/icons-material/Healing'
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye'

function IntegratedPest() {
  return (
    <div className="integrated-pest-container">
      <h1>Integrated Pest Management (IPM) Service</h1>
      <p>
        IPM is an ecosystem-based strategy that focuses on long-term prevention
        of pests or their damage through a combination of techniques such as
        biological control, habitat manipulation, modification of cultural
        practices, and use of resistant varieties. Pesticides are used only
        after monitoring indicates they are needed according to established
        guidelines, and treatments are made with the goal of removing only the
        target organism. Pest control materials are selected and applied in a
        manner that minimizes risks to human health, beneficial and nontarget
        organisms, and the environment.
      </p>
      <div className="integrated-pest-banner">
        <div className="integrated-pest-steps">
          <div className="integrated-pest-step inspection">
            <ManageSearchIcon className="integrated-pest-icon" />
            <h2>STEP1: &nbsp;INSPECTION</h2>
            <hr className="integrated-pest-line"></hr>
          </div>
          <div className="integrated-pest-step treatment">
            <HealingIcon className="integrated-pest-icon" />
            <h2>STEP2: &nbsp;TREATMENT</h2>
            <hr className="integrated-pest-line"></hr>
          </div>
          <div className="integrated-pest-step monitor">
            <RemoveRedEyeIcon className="integrated-pest-icon" />
            <h2>STEP3: &nbsp;MONITOR</h2>
            <hr className="integrated-pest-line"></hr>
            <p>
              Monitoring means checking your field, landscape, forest, or
              building—or other site—to identify which pests are present, how
              many there are, or what damage they’ve caused. Correctly
              identifying the pest is key to knowing whether a pest is likely to
              become a problem and determining the best management strategy.
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default IntegratedPest
