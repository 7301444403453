export const productInfo = [
  {
    name: 'Entice 1',
    description: 'Fly Trap with Sticky Pad - ENTICE 1 (From Korea)',
    img: '/images/Products/Entice1.jpg',
    png: '/images/Products/Entice1.png',
  },
  {
    name: 'DENGX Fly Trap',
    description:
      'Brand tube light that attract mosquito, files moths and other insects',
    img: '/images/Products/img-dengx-flytrap.jpg',
    png: '/images/Products/img-dengx-flytrap.png',
  },
  {
    name: 'DENGX Waterproof Bug Zapper',
    description: 'Indoor and outdoor available bug zapper',
    img: '/images/Products/img-EIK-Dengx.jpg',
    png: '/images/Products/img-EIK-Dengx.png',
  },
  {
    name: 'Super Insect Killer 40W',
    description:
      'Special open design on both sides enlarges coverage area and attracts insects more effectively.',
    img: '/images/Products/img-super-insect-killer.jpg',
    png: '/images/Products/img-super-insect-killer.png',
  },
  {
    name: 'Gachron Trap',
    description: 'Pheromone trap for monitoring moths',
    img: '/images/Products/img-gachon-trap.jpg',
    png: '/images/Products/img-gachon-trap.png',
  },
  {
    name: 'TORIOS',
    description:
      'Pheromone device for monitoring of crawling stored-product insects.',
    img: '/images/Products/img-torios.jpg',
    png: '/images/Products/img-torios.png',
  },
  {
    name: 'Xlure RTU',
    description: 'Monitoring trap for stored product insects',
    img: '/images/Products/img-xlure-rtu.jpg',
    png: '/images/Products/img-xlure-rtu.png',
  },
  {
    name: 'Multi Species Beetle Trap',
    description:
      'Attracts wide range of beetles, borers, weevils and their larvae',
    img: '/images/Products/img-mst-kit.png',
    png: '/images/Products/img-mst-kit.png',
  },
]
