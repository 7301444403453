import React from 'react'
import ServiceCard from './ServiceCard'

function ServiceCards() {
  return (
    <>
      <br></br>
      <h1>ALPHA PEST SERVICES</h1>
      <div className="cards__container">
        <div className="cards__wrapper">
          <ul className="cards__items">
            <ServiceCard
              w1="PEST"
              w2="CONTROL"
              class="pest-control-bg"
              link="/services/pest"
            />
            <ServiceCard
              w1="TERMITE"
              w2="ELIMINATION"
              class="termite-elimination-bg"
              link="/services/termite"
            />
            <ServiceCard
              w1="INTEGRATED PEST"
              w2="MANAGEMENT"
              class="integrated-pest-bg"
              link="/services/integrated"
            />
          </ul>
        </div>
      </div>
    </>
  )
}

export default ServiceCards
