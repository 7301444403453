import React from 'react'
import './Services.css'

function Services(props) {
  window.scrollTo({
    top: 0,
    left: 0,
    behavior: 'smooth',
  })
  return (
    <>
      <div className="services-content"></div>
      {props.content}
    </>
  )
}

export default Services
