import React from 'react'
import { Link } from 'react-router-dom'
import './Pest.css'
import Description from './Description'
import HomeIcon from '@mui/icons-material/Home'
import LocalDiningIcon from '@mui/icons-material/LocalDining'
import ErrorIcon from '@mui/icons-material/Error'
import ThumbDownAltIcon from '@mui/icons-material/ThumbDownAlt'
import AccessTimeIcon from '@mui/icons-material/AccessTime'
import SchoolIcon from '@mui/icons-material/School'

function Pest(props) {
  window.scrollTo({
    top: 0,
    left: 0,
    behavior: 'smooth',
  })

  return (
    <>
      <div
        className="pest-bg"
        style={{
          backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${props.img})`,
          backgroundPosition: 'center',
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
        }}
      >
        <span id="pest-description">
          {props.info.name}
          <p>{props.info.profile}</p>
          <Description
            description={props.info.habitat}
            icon={<HomeIcon className="pest-icon" />}
            label={'Distribution'}
          />
          <Description
            description={props.info.food}
            icon={<LocalDiningIcon className="pest-icon" />}
            label={'Food'}
          />
          <Description
            description={props.info.time}
            icon={<AccessTimeIcon className="pest-icon" />}
            label={'Active Time'}
          />
          <Description
            description={props.info.harm}
            icon={<ErrorIcon className="pest-icon" />}
            label={'Harmful'}
          />
          <Description
            description={props.info.weak}
            icon={<ThumbDownAltIcon className="pest-icon" />}
            label={'Weakness'}
          />
          <Description
            description={props.info.knowledge}
            icon={<SchoolIcon className="pest-icon" />}
            label={'Extra Knowledge'}
          />
        </span>
      </div>
    </>
  )
}

export default Pest
