import React from 'react'
import './TermiteElim.css'
import YoutubeEmbed from './YoutubeEmbed'

function TermiteElim() {
  return (
    <div className="termite-eliminate-container">
      <h1>Termite Elimination Service</h1>
      <div className="termite-eliminate-content">
        <div className="row" style={{ display: 'block' }}>
          <div className="column left">
            <YoutubeEmbed embedId="kYqa9kGBuOg" />
          </div>
          <div className="column right">
            <h2>Termite Baiting</h2>
            <p className="termite-eliminate-description">
              ExterminexTM is a revolutionary termite baiting technology
              incorporating a highly palatable matrix infused with a super
              efficient, user friendly active ingredient.
            </p>
            <hr className="termite-eliminate-line"></hr>
          </div>
        </div>
      </div>
    </div>
  )
}

export default TermiteElim
