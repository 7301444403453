import * as React from 'react'
import { useParams } from 'react-router-dom'
import './Product.css'

function Product(props) {
  window.scrollTo({
    top: 0,
    left: 0,
    behavior: 'smooth',
  })
  return (
    <div className="product-container">
      <div className="product-content">
        <div className="row" style={{ display: 'block' }}>
          <div className="column left">
            <img id="product-img" src={props.info.png} alt="" />
          </div>
          <div className="column right">
            <h2>{props.info.name}</h2>
            <p className="product-description">{props.info.description}</p>
            <hr className="product-line"></hr>
            <table className="product-table">
              <tbody>
                <tr>
                  <td>Material</td>
                  <td>ABS Plastic, UV Lamp, Paper with adhesive</td>
                </tr>
                <tr>
                  <td>Dimensions</td>
                  <td>W: 450mm, L: 350mm, T: 130 mm</td>
                </tr>
                <tr>
                  <td>Weight</td>
                  <td>Approximately 3kg</td>
                </tr>
              </tbody>
            </table>
            <hr className="product-line"></hr>
          </div>
        </div>
        <div className="row">
          <div className="column">
            <h2>Description</h2>
            <p className="product-description">
              This attractive wall-mounted unit disguised as a wall light. It is
              perfect choice for areas where fly control should be discreet,e.g
              restaurants,hotels,dining rooms,hospitals,convenience
              stores,supermarkets and all public areas where flyinginsect
              management should be hidden from view.
            </p>
            <br></br>
            <h2>Key Features</h2>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Product
