import React from 'react'
import './About.css'

function About() {
  window.scrollTo({
    top: 0,
    left: 0,
    behavior: 'smooth',
  })
  return (
    <>
      <div className="about-content">
        <span id="about-text">
          ABOUT ALPHA PEST
          <br />
          <div className="about-small">
            <b>
              <i>ALPHA PEST SERVICES</i>
            </b>{' '}
            is a company that provides termite treatment, pest control service,
            integrated pest management (IPM) service as well as supply and
            service of Electrical Insect Traps and Pest Control Products
          </div>
        </span>
      </div>
    </>
  )
}

export default About
