import React from 'react'
import './Cards.css'
import CardItem from './CardItem'

function Cards() {
  return (
    <div className="cards">
      <h1>Solutions</h1>
      <div className="cards__container">
        <div className="cards__wrapper">
          <ul className="cards__items">
            <CardItem
              src="images/img-restaurant.jpg"
              text="The first line of defense against pests for your home and business"
              label="FOR YOUR HOME & BUSINESS"
              path="/services"
            />
            <CardItem
              src="images/Products/Entice1.jpg"
              text="Our products consist of Fly Traps, Electrical Insect Killer and etc."
              label="OUR PRODUCTS"
              path="/products"
            />
            <CardItem
              src="images/img-pest-control.jpg"
              text="Learn more about our company Alpha Pest Services"
              label="ABOUT US"
              path="/about"
            />
          </ul>
        </div>
      </div>
    </div>
  )
}

export default Cards
