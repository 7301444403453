import React from 'react'
import { Link } from 'react-router-dom'

function ServiceCard(props) {
  return (
    <li className="cards__item">
      <Link className="card__pest_link" to={props.link}>
        <div className={props.class}>
          <p>
            <span>{props.w1}</span> {props.w2}
          </p>
        </div>
      </Link>
    </li>
  )
}

export default ServiceCard
