import React from 'react'
import './Products.css'

function Products(props) {
  window.scrollTo({
    top: 0,
    left: 0,
    behavior: 'smooth',
  })

  return (
    <>
      <div className="products-content"></div>
      {props.content}
    </>
  )
}

export default Products
