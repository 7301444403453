import React from 'react'
import Navbar from './components/Navbar'
import './App.css'
import { BrowserRouter } from 'react-router-dom'
import AppRouter from 'AppRouter'
import Footer from 'components/Footer'

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Navbar />
        <AppRouter />
        <Footer />
      </BrowserRouter>
    </div>
  )
}

export default App
