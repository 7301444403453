import React from 'react'
import './Home.css'
import Cards from 'components/Cards'
import { Link } from 'react-router-dom'
import { pestObj } from './Home.config'

function Home() {
  window.scrollTo({
    top: 0,
    left: 0,
    behavior: 'smooth',
  })

  return (
    <>
      <div className="vision-home">
        <span id="vision-text">
          PROTECT YOUR HOMES & BUSINESSES FROM PESTS
          <br />
          <div className="vision-small">
            Our highly experience team delivers an effective sustainable and
            humaine service - while removing unnecessary costs and disrruptions
            to your home or business
          </div>
        </span>
      </div>
      <div
        style={{
          backgroundColor: '#75B84F',
          height: 'auto',
          textAlign: 'center',
          padding: '100px',
          color: 'white',
          fontSize: '20px',
        }}
      >
        PESTS TO MANAGE
        <div className="pest-container">
          <div className="pest-wrapper">
            <ul className="pest-items">
              {pestObj.map((p, i) => (
                <li
                  className="pest-item"
                  key={i}
                  style={{ marginBottom: '100px' }}
                >
                  <div
                    className="circletag"
                    style={{
                      borderRadius: '50%',
                      background: 'white',
                      width: '80px',
                      height: '80px',
                    }}
                  >
                    <Link to={p.link} className="nav-pest">
                      <div style={{ paddingTop: '13px', textAlign: 'center' }}>
                        <img
                          id="pest-img"
                          src={p.source}
                          width="50px"
                          alt={p.name}
                        ></img>
                        <br />
                        <br />
                        <div>{p.name}</div>
                      </div>
                    </Link>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
      <Cards />
    </>
  )
}

export default Home
