import React from 'react'
import './Pests.css'
import { pestInfo } from './Pest/Pest.config'
import PestCard from './PestCard'

function Pests() {
  window.scrollTo({
    top: 0,
    left: 0,
    behavior: 'smooth',
  })

  var elements = []
  var cards = []

  const populatePestCard = (p, i) => {
    elements = []
    cards.push(
      <PestCard
        key={i}
        bg={p.bg}
        name={p.name}
        link={'/' + p.path}
        mgl={p.mgl}
      />,
    )
    if (((i + 1) % 3 === 0 || i === pestInfo.length - 1) && i !== 0) {
      if (i === pestInfo.length - 1 && (i + 1) % 3 !== 0) {
        cards.push(<li key={'li' + i} className="cards__item"></li>)
      }
      elements.push(
        <ul className="cards__items" key={i}>
          {cards}
        </ul>,
      )
      cards = []
      return elements
    }
  }

  return (
    <>
      <div className="pests-content"></div>
      <br></br>
      <h1>PEST INSIGHT</h1>
      <div className="cards__container">
        <div className="cards__wrapper">
          {pestInfo.map((p, i) => {
            return populatePestCard(p, i)
          })}
        </div>
      </div>
    </>
  )
}

export default Pests
