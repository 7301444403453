import React from 'react'
import { Link } from 'react-router-dom'
import { Button } from '@mui/material'

function PestCard(props) {
  return (
    <li className="cards__item">
      <Link className="card__pest_link" to={props.link}>
        <h2>{props.name}</h2>
        <div className="circular--landscape">
          <img
            src={props.bg}
            alt="Avatar"
            style={{ marginLeft: props.mgl }}
          ></img>
        </div>
        <div className="cards__item__info">
          <Button
            className="pests-button"
            variant="contained"
            sx={{
              ':hover': {
                bgcolor: 'grey',
              },
            }}
          >
            LEARN MORE
          </Button>
        </div>
      </Link>
    </li>
  )
}

export default PestCard
