import React, { useState, useEffect } from 'react'
import { Button } from './Button'
import { Link } from 'react-router-dom'
import './Navbar.css'
import MenuIcon from '@mui/icons-material/Menu'
import MenuOpenIcon from '@mui/icons-material/MenuOpen'

function Navbar() {
  const [click, setClick] = useState(false)
  const [button, setButton] = useState(true)
  const [logoClick, setLogoClick] = useState({ iframeMouseOver: false })

  const handleClick = () => setClick(!click)
  const closeMobileMenu = () => setClick(false)

  const showButton = () => {
    if (window.innerWidth <= 960) {
      setButton(false)
    } else {
      setButton(true)
    }
  }

  useEffect(() => {
    showButton()
  }, [])

  return (
    <>
      <nav className="navbar">
        <div className="navbar-container">
          <div className="navbar-logo">
            <iframe
              src="/images/alpha_pest_logo.svg"
              frameBorder="0"
              title="alpha pest logo"
              className="logo-iframe"
            ></iframe>
          </div>

          <div className="menu-icon" onClick={handleClick}>
            {click ? <MenuOpenIcon /> : <MenuIcon />}
          </div>
          <ul className={click ? 'nav-menu active' : 'nav-menu'}>
            <li className="nav-item">
              <Link to="/" className="nav-links" onClick={closeMobileMenu}>
                Home
              </Link>
            </li>
            <li className="nav-item">
              <Link
                to="/services"
                className="nav-links"
                onClick={closeMobileMenu}
              >
                Services
              </Link>
              <ul className="dropdown">
                <li>
                  <Link to="/services/pest" className="nav-sub-links">
                    Pest Control
                  </Link>
                </li>
                <li>
                  <Link to="/services/termite" className="nav-sub-links">
                    Termite Elimination
                  </Link>
                </li>
                <li>
                  <Link to="/services/integrated" className="nav-sub-links">
                    Integrated Pest Management (IPM) Service
                  </Link>
                </li>
              </ul>
            </li>
            <li className="nav-item">
              <Link
                to="/products"
                className="nav-links"
                onClick={closeMobileMenu}
              >
                Products
              </Link>
            </li>
            <li className="nav-item">
              <Link to="/pest" className="nav-links" onClick={closeMobileMenu}>
                Pest Insight
              </Link>
              <ul className="dropdown">
                <li>
                  <Link to="/pest/fly" className="nav-sub-links">
                    Fly
                  </Link>
                </li>
                <li>
                  <Link to="/pest/cockroach" className="nav-sub-links">
                    Cockroach
                  </Link>
                </li>
                <li>
                  <Link to="/pest/ant" className="nav-sub-links">
                    Ant
                  </Link>
                </li>
                <li>
                  <Link to="/pest/mosquito" className="nav-sub-links">
                    Mosquito
                  </Link>
                </li>
                <li>
                  <Link to="/pest/rat" className="nav-sub-links">
                    Rat
                  </Link>
                </li>
                <li>
                  <Link to="/pest/termite" className="nav-sub-links">
                    Termite
                  </Link>
                </li>
              </ul>
            </li>
            <li className="nav-item">
              <Link to="/about" className="nav-links" onClick={closeMobileMenu}>
                About
              </Link>
            </li>
          </ul>
        </div>
      </nav>
    </>
  )
}

export default Navbar
