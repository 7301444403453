import React from 'react'
import './Footer.css'
import { Button } from './Button'
import { Link } from 'react-router-dom'

function Footer() {
  return (
    <div className="footer-container">
      <div className="footer-links">
        <div className="footer-link-wrapper">
          <div className="footer-link-items">
            <h2>Knowing Us</h2>
            <Link to="/">Home</Link>
            <Link to="/about">About ALPHA PEST</Link>
          </div>
          <div className="footer-link-items">
            <h2>Our Services</h2>
            <Link to="/services/pest">Pest Control</Link>
            <Link to="/services/termite">Termite Elimination</Link>
            <Link to="/services/integrated">
              Integrated Pest Management Service (IPM)
            </Link>
          </div>
        </div>
        <div className="footer-link-wrapper">
          <div className="footer-link-items">
            <h2>Quick Links</h2>
            <Link to="/services">Services</Link>
            <Link to="/products">Products</Link>
            <Link to="/pest">Pest Insights</Link>
            <Link to="/about">About Us</Link>
          </div>
        </div>
      </div>
      <section className="social-media">
        <div className="social-media-wrap">
          <div className="footer-logo">
            <Link to="/" className="social-logo">
              ALPHA PEST
              <i className="fab fa-typo3" />
            </Link>
          </div>
          <small className="website-rights">ALPHA PEST © 2023</small>
          <div className="social-icons">
            <Link
              className="social-icon-link facebook"
              to="/"
              target="_blank"
              aria-label="Facebook"
            >
              <i className="fab fa-facebook-f" />
            </Link>
            <Link
              className="social-icon-link instagram"
              to="/"
              target="_blank"
              aria-label="Instagram"
            >
              <i className="fab fa-instagram" />
            </Link>
            <Link
              className="social-icon-link youtube"
              to="/"
              target="_blank"
              aria-label="Youtube"
            >
              <i className="fab fa-youtube" />
            </Link>
            <Link
              className="social-icon-link twitter"
              to="/"
              target="_blank"
              aria-label="Twitter"
            >
              <i className="fab fa-twitter" />
            </Link>
            <Link
              className="social-icon-link twitter"
              to="/"
              target="_blank"
              aria-label="LinkedIn"
            >
              <i className="fab fa-linkedin" />
            </Link>
          </div>
        </div>
      </section>
    </div>
  )
}

export default Footer
