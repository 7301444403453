import React from 'react'
import Navbar from './components/Navbar'
import './App.css'
import Home from './components/pages/Home/Home'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import Services from './components/pages/Services/Services'
import Products from './components/pages/Products/Products'
import About from './components/pages/About/About'
import Pests from './components/pages/Pests/Pests'
import Pest from './components/pages/Pests/Pest/Pest'
import { pestInfo } from 'components/pages/Pests/Pest/Pest.config'
import { productInfo } from 'components/pages/Products/Product/Product.config'
import Product from 'components/pages/Products/Product/Product'
import NoRoute from 'NoRoute'
import ServiceCards from 'components/pages/Services/ServiceCards'
import PestControl from 'components/pages/Services/PestControl/PestControl'
import IntegratedPest from 'components/pages/Services/IntegratedPest/IntegratedPest'
import TermiteElim from 'components/pages/Services/TermiteElim/TermiteElim'
import ProductCards from 'components/pages/Products/ProductCards'

function AppRouter() {
  return (
    <Routes>
      <Route index element={<Home />} />
      <Route
        path="services"
        element={<Services content={<ServiceCards />} />}
      />
      <Route
        path="services/pest"
        element={<Services content={<PestControl />} />}
      />
      <Route
        path="services/termite"
        element={<Services content={<TermiteElim />} />}
      />
      <Route
        path="services/integrated"
        element={<Services content={<IntegratedPest />} />}
      />
      <Route
        path="products"
        element={<Products content={<ProductCards />} />}
      />
      {productInfo.map((p, i) => (
        <Route
          key={'products' + i}
          path={'products/' + i}
          element={<Products content={<Product img={p.img} info={p} />} />}
        />
      ))}
      <Route path="about" element={<About />} />
      <Route path="pest" element={<Pests />} />
      {pestInfo.map((p, i) => (
        <Route
          key={'pest' + i}
          path={p.path}
          element={<Pest img={p.bg} info={p} />}
        />
      ))}
      <Route path="*" element={<NoRoute />} />
    </Routes>
  )
}

export default AppRouter
