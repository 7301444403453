import React from 'react'
import './PestControl.css'
import { pestObj } from 'components/pages/Home/Home.config'

function PestControl() {
  return (
    <>
      <div className="pest-control-container">
        <h1>Pest Control Service</h1>
        <p>
          Alpha Pest Services offers Pest Control Services for the control and
          elimination of pest such as flies, cockroaches, ants, mosquitoes, rats
          and termites:
        </p>

        <div className="pest-control-banner">
          <div className="pest-control-steps">
            {pestObj.map((p, i) => (
              <div
                className={`pest-control-step ${p.name.toLowerCase()}`}
                key={i}
              >
                <div className="pest-control-circletag">
                  <img
                    id="pest-img"
                    src={'/' + p.source}
                    width="50px"
                    alt={p.name}
                  ></img>
                </div>
                <h2>{p.name}</h2>
                <hr className="pest-control-line"></hr>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  )
}

export default PestControl
