export const pestObj = [
  {
    source: 'images/fly-shape.png',
    name: 'Fly',
    link: '/pest/fly',
  },
  {
    source: 'images/cockroach.png',
    name: 'Cockroach',
    link: '/pest/cockroach',
  },
  {
    source: 'images/ant.png',
    name: 'Ant',
    link: '/pest/ant',
  },
  {
    source: 'images/mosquito.png',
    name: 'Mosquito',
    link: '/pest/mosquito',
  },
  {
    source: 'images/rat-looking-right.png',
    name: 'Rat',
    link: '/pest/rat',
  },
  {
    source: 'images/termite.png',
    name: 'Termite',
    link: '/pest/termite',
  },
]
