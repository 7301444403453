import React from 'react'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import Tooltip from '@mui/material/Tooltip'

function Description(props) {
  return (
    <>
      <Stack className="pest-small" direction="row" alignItems="center" gap={1}>
        <Tooltip title={props.label}>{props.icon}</Tooltip>
        <Typography style={{ fontFamily: "'Times New Roman', Times, serif" }}>
          {props.description}
        </Typography>
      </Stack>
    </>
  )
}

export default Description
